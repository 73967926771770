////React
import React, { useEffect, useState } from "react";

////React Dom
import { useParams, useHistory } from "react-router-dom";

////Layout Sucess
import Sucess from "../../components/responses/Sucess";

////AntDesign
import {
  Card,
  Row,
  Col,
  PageHeader,
  Avatar,
  Button,
  Typography,
  Modal,
  Segmented,
  Input,
  List,
} from "antd";

import { SearchOutlined } from "@ant-design/icons";

///Axios
import Axios from "axios";
import "moment/locale/es";
import moment from "moment";

/////Helpers
import { getToken } from "../../Helpers/auth-helpers";

//////Return del componente
const Declinado = ({ usuario }) => {
  const [nombre] = usuario;

  const { Meta } = Card;

  ////History react Dom
  const history = useHistory();

  const [datos, setdatos] = useState([]);

  const [modalFiltradoGlobal, setModalFiltradoGlobal] = useState(false);
  const [searchTermGlobal, setSearchTermGlobal] = useState("");
  const [searchTypeGlobal, setSearchTypeGlobal] = useState("Nombre");
  const [resultsGlobal, setResultsGlobal] = useState([]);

  const [modalSucess, setModalSucess] = useState(false);

  ////UseParams
  const { nombreRequerimiento } = useParams();

  console.log("Token de reclu", getToken());

  const cargaDatos = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    try {
      const [datosResp] = await Promise.all([
        Axios.get(
          "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=1000&filters[estado][$eq]=Declinado&populate[mother]=*&sort=id:desc",
          { headers }
        ),
      ]);

      console.log(
        "Datos de Aplicado y Confirmacion entrevista 1:",
        datosResp.data.data
      );
      setdatos(datosResp.data.data);
    } catch (error) {
      console.error("Error al cargar los datos:", error);
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  /////funcion enviar
  const enviarPagina = () => {
    console.log("**************** Enviar pagina");
    history.push({ pathname: "/solicitudes/perfiles" });
  };

  /////funcion enviar a Formulario
  const Enviar = (id) => {
    console.log("**************** Enviar pagina", id);
    history.push({
      pathname: "../FormularioPerfil", ///Direccion a donde dirige
      search: `?id=${id}`, //parametros a enviar
      state: {
        //Estado
        update: true,
      },
    });
  };

  const borrarManejaSolo = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const DatosBorrados = await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/manejasolos/${id}`,
      { headers }
    );

    console.log("Borre los Datos", DatosBorrados);

    return DatosBorrados;
  };

  const borrarManejaPersonas = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const DatosBorrados = await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/manejapersonas/${id}`,
      { headers }
    );

    console.log("Borre los Datos", DatosBorrados);

    return DatosBorrados;
  };

  const borrarManejaEquipos = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const DatosBorrados = await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/manejaequipos/${id}`,
      { headers }
    );

    console.log("Borre los Datos", DatosBorrados);

    return DatosBorrados;
  };

  const borrarManejaAreas = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const DatosBorrados = await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/manejaareas/${id}`,
      { headers }
    );

    console.log("Borre los Datos", DatosBorrados);

    return DatosBorrados;
  };

  const ReactivarDeclinado = async (data) => {
    console.log("Datos del perfil a reactivar", data);

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const dataasignados = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${data.attributes.mother.data.id}`,
      { headers }
    );

    console.log("Datos asignados", dataasignados);

    if (dataasignados.data.length >= 1) {
      console.log("hay Datos", dataasignados);

      let Entrevista = dataasignados.data[0].Entrevista;

      console.log("Tipo Entrevista", Entrevista);

      if (Entrevista === "Maneja Solo") {
        const Datosborrar = await Axios.get(
          `https://rh.api.prontobpo.cloud/api/manejasolos?filters[IdMother][$eq]=${data.recruitify_mother.id}`,
          { headers }
        );

        console.log("Respuesta borrado", Datosborrar);

        if (Datosborrar.data.length > 0) {
          borrarManejaSolo(Datosborrar.data[0].id);
        }

        borrarAsignado(dataasignados.data[0].id);
        actualizarEstado(data.id);
        agregadoLogReactivar(data.attributes.mother.data.id);
        setModalSucess(true);
      } else if (Entrevista === "Maneja Personas") {
        const Datosborrar = await Axios.get(
          `https://rh.api.prontobpo.cloud/api/manejapersonas?filters[idMother][$eq]=${data.recruitify_mother.id}`,
          { headers }
        );

        console.log("Respuesta borrado", Datosborrar);

        if (Datosborrar.data.length > 0) {
          borrarManejaPersonas(Datosborrar.data[0].id);
        }
        borrarAsignado(dataasignados.data[0].id);
        actualizarEstado(data.id);
        agregadoLogReactivar(data.attributes.mother.data.id);
        setModalSucess(true);
      } else if (Entrevista === "Maneja Equipos") {
        const Datosborrar = await Axios.get(
          `https://rh.api.prontobpo.cloud/api/manejaequipos?filters[idMother][$eq]=${data.recruitify_mother.id}`,
          { headers }
        );

        console.log("Respuesta borrado", Datosborrar);
        if (Datosborrar.data.length > 0) {
          borrarManejaEquipos(Datosborrar.data[0].id);
        }
        borrarAsignado(dataasignados.data[0].id);
        actualizarEstado(data.id);
        agregadoLogReactivar(data.attributes.mother.data.id);
        setModalSucess(true);
      } else if (Entrevista === "Maneja Areas") {
        const Datosborrar = await Axios.get(
          `https://rh.api.prontobpo.cloud/api/manejaareas?filters[idMother][$eq]=${data.recruitify_mother.id}`,
          { headers }
        );

        console.log("Respuesta borrado", Datosborrar);
        if (Datosborrar.data.length > 0) {
          borrarManejaAreas(Datosborrar.data[0].id);
        }
        borrarAsignado(dataasignados.data[0].id);
        actualizarEstado(data.id);
        agregadoLogReactivar(data.attributes.mother.data.id);
        setModalSucess(true);
      }
    } else {
      console.log("No Hay Datos");
      actualizarEstado(data.id);
      agregadoLogReactivar(data.attributes.mother.data.id);
      setModalSucess(true);
    }
  };

  const borrarAsignado = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const DatosBorrados = await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/asignados/${id}`,
      { headers }
    );

    console.log("Borre los Datos de Asignado", DatosBorrados);

    return DatosBorrados;
  };

  const actualizarEstado = async (id) => {
    const datosActualizar = JSON.stringify({
      data: {
        estado: "Aplicado",
      },
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const estadoActualizado = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/perfiles/${id}`,
      datosActualizar,
      { headers }
    );

    console.log("Actualice el estado", estadoActualizado);

    return estadoActualizado;
  };

  const agregadoLogReactivar = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datos = JSON.stringify({
      data: {
        funcion: "Reactivacion de usuario declinado",
        fecha: moment(Date.now()).format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: nombre,
        idMother: id,
      },
    });

    console.log("Llego al envio del Log", datos);

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datos,
      { headers }
    );

    console.log("Datos a guardar del log", data);

    return data;
  };

  const recargarPagina = () => {
    setModalSucess(false);
    window.location.reload();
  };

  const handleSegmentChangeGlobal = (value) => {
    // Cambiar el tipo de búsqueda
    setSearchTypeGlobal(value);

    // Limpiar el término de búsqueda y los resultados
    setSearchTermGlobal("");
    setResultsGlobal([]);
  };

  const handleSearchGlobal = async () => {
    let filterQuery;
    console.log(searchTypeGlobal, searchTermGlobal);

    switch (searchTypeGlobal) {
      case "Nombre":
        filterQuery = `nombre: { contains: "${searchTermGlobal}" }`;
        break;
      case "Correo":
        filterQuery = `correo: { contains: "${searchTermGlobal}" }`;
        break;
      case "DPI":
        filterQuery = `dpi: { contains: "${searchTermGlobal}" }`;
        break;
      default:
        filterQuery = `nombre: { contains: "${searchTermGlobal}" }`;
    }

    const query = `query {
      mothers(
        filters: { 
          perfile: { estado: { eq: "Declinado" } },
          ${filterQuery}
        }
      ) {
        data {
          id
          attributes {
            nombre
            apellido
            correo
            dpi
            perfile {
              data {
                id
                attributes{
                  estado
                }
              }
            }
          }
        }
      }
    }`;

    try {
      const response = await Axios.post(
        `https://rh.api.prontobpo.cloud/graphql`,
        { query },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setResultsGlobal(response.data.data.mothers.data);
    } catch (error) {
      console.error("Error fetching mothers:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Obtener componentes de la fecha
    const day = String(date.getDate()).padStart(2, "0"); // Día
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Mes (0-11)
    const year = date.getFullYear(); // Año
    const hours = String(date.getHours()).padStart(2, "0"); // Horas
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Minutos

    // Formatear la fecha
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  return (
    <div>
      <PageHeader
        onBack={() => enviarPagina()}
        title={nombreRequerimiento}
        subTitle={false}
      />

      <Row justify="space-between" align="top">
        <Col span={4}>
          <Typography.Title level={2}>Perfiles</Typography.Title>
        </Col>
        <Col span={6}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => setModalFiltradoGlobal(true)}
          >
            Busqueda Declinados
          </Button>
        </Col>
      </Row>

      <br />

      <Row gutter={16} justify="space-around">
        {datos.map((data) => (
          <Col key={data.id} xs={24} sm={12} md={8} lg={8}>
            <Card
              style={{
                marginBottom: 16,
                maxWidth: "100%", // Limita el ancho de la tarjeta
              }}
            >
              <Meta
                avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                title={
                  <div
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    {data.attributes.nombre}
                  </div>
                }
                description={
                  <div
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    Estado: {data.attributes.estado}
                    <br />
                    Posicion Aplica:{" "}
                    {data.attributes.mother.data.attributes.posicionAplica}
                    <br />
                    Fecha Creacion:{" "}
                    {formatDate(
                      data.attributes.mother.data.attributes.createdAt
                    )}
                  </div>
                }
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 16,
                }}
              >
                <Button type="primary" onClick={() => Enviar(data.id)}>
                  Ver
                </Button>
                <Button
                  danger
                  type="primary"
                  onClick={() => ReactivarDeclinado(data)}
                >
                  Reactivar Aplicante
                </Button>
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      {
        <Modal
          title="Busqueda Candidatos en estado Declinado"
          open={modalFiltradoGlobal}
          onOk={() => setModalFiltradoGlobal(false)}
          onCancel={() => setModalFiltradoGlobal(false)}
        >
          <Row gutter={[16, 16]} justify="space-around" align="middle">
            <Col span={24}>
              <Segmented
                options={["Nombre", "Correo", "DPI"]}
                onChange={handleSegmentChangeGlobal}
              />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Selecciona un tipo de búsqueda"
                value={searchTermGlobal}
                onChange={(e) => setSearchTermGlobal(e.target.value)}
                onPressEnter={handleSearchGlobal}
              />
            </Col>
            <Col span={24}>
              <Button onClick={handleSearchGlobal}>Buscar</Button>
            </Col>
            <Col span={24}>
              <List
                itemLayout="horizontal"
                dataSource={resultsGlobal}
                renderItem={(item) => (
                  console.log("Datos del item", item),
                  (
                    <List.Item
                      key={item.attributes.perfile.data.id}
                      actions={[
                        <a
                          key="list-loadmore-more"
                          onClick={() =>
                            Enviar(item.attributes.perfile.data.id)
                          }
                        >
                          Ver Perfil
                        </a>,
                      ]}
                    >
                      {" "}
                      {/* Asegúrate de que cada item tiene una clave única */}
                      <List.Item.Meta
                        title={`Nombre: ${
                          item.attributes.nombre +
                          " " +
                          item.attributes.apellido
                        }`}
                        description={`Correo: ${item.attributes.correo}`}
                        // ... otros campos
                      />
                      {`DPI: ${item.attributes.dpi}`}
                      <br />
                      {`Estado App: ${item.attributes.perfile.data.attributes.estado}`}
                    </List.Item>
                  )
                )}
              />
            </Col>
          </Row>
        </Modal>
      }

      {
        ////Modal
        <Modal
          centered
          visible={modalSucess}
          onOk={() => setModalSucess(false)}
          onCancel={() => setModalSucess(false)}
          footer={
            <Button type="primary" onClick={() => recargarPagina()}>
              Salir
            </Button>
          }
        >
          <Sucess titulo="Se ah regresado al aplicante al inicio del track!" />
        </Modal>
      }
    </div>
  );
};

export default Declinado;
