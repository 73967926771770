////React
import React, { useEffect, useState } from "react";

////React Dom
import { useParams, useHistory } from "react-router-dom";


////AntDesign
import {
  Card,
  Row,
  Col,
  PageHeader,
  Avatar,
  Button,
  Typography,
  Modal,
  Segmented,
  Input,
  List,
} from "antd";

import { SearchOutlined } from "@ant-design/icons";

///Axios
import Axios from "axios";
import "moment/locale/es";

/////Helpers
import { getToken } from "../../Helpers/auth-helpers";

//////Return del componente
const Aplicado = ({ usuario }) => {
  const { Meta } = Card;

  ////History react Dom
  const history = useHistory();

  const [datos, setdatos] = useState([]);

  const [modalFiltradoGlobal, setModalFiltradoGlobal] = useState(false);
  const [searchTermGlobal, setSearchTermGlobal] = useState("");
  const [searchTypeGlobal, setSearchTypeGlobal] = useState("Nombre");
  const [resultsGlobal, setResultsGlobal] = useState([]);

  ////UseParams
  const { nombreRequerimiento } = useParams();

  console.log("Token de reclu", getToken());

  const cargaDatos = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    try {
      const [datosResp] = await Promise.all([
        Axios.get(
          "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=1000&filters[estado][$in]=Aplicado&filters[estado][$in]=Confirmacion entrevista 1&populate[mother]=*&sort=id:desc",
          { headers }
        ),
      ]);

      console.log(
        "Datos de Aplicado y Confirmacion entrevista 1:",
        datosResp.data.data
      );
      setdatos(datosResp.data.data);
    } catch (error) {
      console.error("Error al cargar los datos:", error);
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  /////funcion enviar
  const enviarPagina = () => {
    console.log("**************** Enviar pagina");
    history.push({ pathname: "/solicitudes/perfiles" });
  };

  /////funcion enviar a Formulario
  const Enviar = (id) => {
    console.log("**************** Enviar pagina", id);
    history.push({
      pathname: "../FormularioPerfil", ///Direccion a donde dirige
      search: `?id=${id}`, //parametros a enviar
      state: {
        //Estado
        update: true,
      },
    });
  };

  const handleSegmentChangeGlobal = (value) => {
    // Cambiar el tipo de búsqueda
    setSearchTypeGlobal(value);

    // Limpiar el término de búsqueda y los resultados
    setSearchTermGlobal("");
    setResultsGlobal([]);
  };

  const handleSearchGlobal = async () => {
    let filterQuery;
    console.log(searchTypeGlobal, searchTermGlobal);

    switch (searchTypeGlobal) {
      case "Nombre":
        filterQuery = `nombre: { contains: "${searchTermGlobal}" }`;
        break;
      case "Correo":
        filterQuery = `correo: { contains: "${searchTermGlobal}" }`;
        break;
      case "DPI":
        filterQuery = `dpi: { contains: "${searchTermGlobal}" }`;
        break;
      case "Posicion Aplica":
          filterQuery = `posicionAplica: { contains: "${searchTermGlobal}" }`;
          break;  
      default:
        filterQuery = `nombre: { contains: "${searchTermGlobal}" }`;
    }

    const query = `query {
      mothers(
        filters: {
         or: [
            { perfile: { estado: { eq: "Aplicado" } } },
            { perfile: { estado: { eq: "Confirmacion entrevista 1" } } }
          ],
          ${filterQuery} 
        }
      ) {
        data {
          id
          attributes {
            nombre
            apellido
            correo
            dpi
            posicionAplica
            perfile {
              data {
                id
                attributes{
                  estado
                }
              }
            }
          }
        }
      }
    }`;

    try {
      const response = await Axios.post(
        `https://rh.api.prontobpo.cloud/graphql`,
        { query },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setResultsGlobal(response.data.data.mothers.data);
    } catch (error) {
      console.error("Error fetching mothers:", error);
    }
  };



  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    // Obtener componentes de la fecha
    const day = String(date.getDate()).padStart(2, '0'); // Día
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes (0-11)
    const year = date.getFullYear(); // Año
    const hours = String(date.getHours()).padStart(2, '0'); // Horas
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Minutos
  
    // Formatear la fecha
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  return (
    <div>
      <PageHeader
        onBack={() => enviarPagina()}
        title={nombreRequerimiento}
        subTitle={false}
      />

      <Row justify="space-between" align="top">
        <Col span={4}>
          <Typography.Title level={2}>Perfiles</Typography.Title>
        </Col>
        <Col span={6}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => setModalFiltradoGlobal(true)}
          >
            Busqueda Aplicados
          </Button>
        </Col>
      </Row>

      <br />

      <Row gutter={16} justify="space-around">
        {datos.map((data) => (
          <Col key={data.id} xs={24} sm={12} md={8} lg={8}>
            <Card
              style={{
                marginBottom: 16,
                maxWidth: "100%", // Limita el ancho de la tarjeta
              }}
            >
              <Meta
                avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                title={
                  <div
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    {data.attributes.nombre}
                  </div>
                }
                description={
                  <div
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    Estado: {data.attributes.estado}
                    <br />
                    Posicion Aplica:{" "}
                    {data.attributes.mother.data.attributes.posicionAplica}
                    <br />
                    Fecha Creacion: {formatDate(data.attributes.mother.data.attributes.createdAt)}
                  </div>
                }
              />
              <br />
              <Button type="primary" onClick={() => Enviar(data.id)}>
                Ver
              </Button>
            </Card>
          </Col>
        ))}
      </Row>

      {
        <Modal
          title="Busqueda Candidatos en estado Aplicado"
          open={modalFiltradoGlobal}
          onOk={() => setModalFiltradoGlobal(false)}
          onCancel={() => setModalFiltradoGlobal(false)}
        >
          <Row gutter={[16, 16]} justify="space-around" align="middle">
            <Col span={24}>
              <Segmented
                options={["Nombre", "Correo", "DPI", "Posicion Aplica"]}
                onChange={handleSegmentChangeGlobal}
              />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Selecciona un tipo de búsqueda"
                value={searchTermGlobal}
                onChange={(e) => setSearchTermGlobal(e.target.value)}
                onPressEnter={handleSearchGlobal}
              />
            </Col>
            <Col span={24}>
              <Button onClick={handleSearchGlobal}>Buscar</Button>
            </Col>
            <Col span={24}>
              <List
                itemLayout="horizontal"
                dataSource={resultsGlobal}
                renderItem={(item) => (
                  console.log("Datos del item", item),
                  (
                    <List.Item
                      key={item.attributes.perfile.data.id}
                      actions={[
                        <a
                          key="list-loadmore-more"
                          onClick={() =>
                            Enviar(item.attributes.perfile.data.id)
                          }
                        >
                          Ver Perfil
                        </a>,
                      ]}
                    >
                      {" "}
                      {/* Asegúrate de que cada item tiene una clave única */}
                      <List.Item.Meta
                        title={`Nombre: ${
                          item.attributes.nombre +
                          " " +
                          item.attributes.apellido
                        }`}
                        description={`Correo: ${item.attributes.correo}`}
                        // ... otros campos
                      />
                      {`Posicion Aplica: ${item.attributes.posicionAplica}`}
                      <br />
                      {`DPI: ${item.attributes.dpi}`}
                      <br />
                      {`Estado App: ${item.attributes.perfile.data.attributes.estado}`}
                    </List.Item>
                  )
                )}
              />
            </Col>
          </Row>
        </Modal>
      }
    </div>
  );
};

export default Aplicado;
